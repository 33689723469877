<template>
  <div class="container">
    <span>评论消息</span>
    <a-empty v-if="msgs.length==0"></a-empty>
    <div class="content" v-if="msgs.length!==0">
      <div class="msgItem" v-for="(item, index) in msgs" :key="item.id">
        <div class="left-box">
          <!-- <img :src="item.fromWho.avatar" alt="" v-if="item.fromWho.avatar!=null" class="avatar" @click="goUser(item.fromWho.userId)" > -->
          <!-- <img src="../../assets/avater.png" v-if="item.fromWho.avatar==null" class="avatar" @click="goUser(item.fromWho.userId)"> -->
          <my-avatar :imgSrc="item.fromWho.avatar" :userName="item.fromWho.userName" :aType="1"
            @click="goUser(item.fromWho.userId)"
          ></my-avatar>
        </div>
        <div class="center-box">
          <div class="line1">
            <!-- <span class="userName" @click="goUser(item.fromWho.userId)">{{item.fromWho.userName}}</span> -->
            <span class="msgTitle" v-if="item.status == 0">评论了你的文章</span>
            <span class="msgTitle" v-if="item.status == 1">删除了他的评论</span>
          </div>
          <div class="line2">
            <span>{{item.content}}</span>
          </div>
          <div class="msgBlog">
            <span>{{item.blog.title}}</span>
            <div class="count">
              <span class="countItem"><heart-filled />{{item.blog.likes}}</span>
              <span class="countItem"><message-outlined />{{item.blog.cmtNum}}</span>
              <span class="countItem"><eye-outlined />{{item.blog.viewNum}}</span>
            </div>
          </div>
          <div class="line3">
            <span class="msgTime"><calendar-outlined />{{item.msgTime}}</span>
            <span class="actionBtn" @click="goDetail(item)"><right-outlined />查看详情</span>
            <span class="actionBtn" @click="delMsg(item)"><delete-outlined />删除消息</span>
          </div>
        </div>
      </div>
      <a-pagination
        style="margin-top: 20px;"
        v-if="msgs.length > 0"
        :total="pagination.total"
        :show-total="total => `总共 ${total} 条消息`"
        :page-size="pagination.size"
        :default-current="1"
        @change="pageChange"
        />
    </div>
  </div>
</template>
<script>
  import * as ACCOUNT from '../../api/account.js';
  import { RightOutlined, DeleteOutlined, HeartFilled, MessageOutlined, CalendarOutlined, EyeOutlined } from '@ant-design/icons-vue';
  import MyAvatar from '@/components/common/MyAvatar'
  export default {
    name: 'CmtsMsgs',
    components: {
      RightOutlined,
      DeleteOutlined,
      HeartFilled,
      MessageOutlined,
      CalendarOutlined,
      EyeOutlined,
      MyAvatar
    },
    data() {
      return {
        msgs: [],
        pagination: {
          current: 1,
          total: 0,
          size: 10
        }
      }
    },
    mounted() {
      this.loadCmtMsgs(1, 10);
    },
    methods: {
      loadCmtMsgs(page, size) {
        let params = new FormData()
        params.append('type', 3)
        params.append('page', page)
        params.append('size', size)
        ACCOUNT.srhUserMsgs(params).then(res=>{
          // console.log('cmt msg', res)
          if(res.data.data.total > 0) {
            this.msgs = res.data.data.records;
            this.pagination.current = res.data.data.current;
            this.pagination.total = res.data.data.total;
            this.pagination.size = res.data.data.size;
          }
        })
      },
      pageChange(page, size) {
        this.loadCmtMsgs(page, size)
      },
      delMsg(item) {
        let _that = this
        this.$confirm({
          title: '删除该条消息',
          content: '一经删除将无法恢复，确定删除？',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            let params = new FormData()
            params.append('id', item.id)
            ACCOUNT.delMsg(params).then(res=>{
              // console.log('del msg', res)
              if(res.data.data) {
                _that.$message.success('删除成功')
                _that.loadCmtMsgs(_that.pagination.current, _that.pagination.size)
              }else {
                _that.$message.error('网络错误')
              }
            })
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
      },
      goDetail(item) {
        // console.log(item)
        if(item.type>0 || item.type<4) {
          this.$router.push({
            path: '/blog',
            query: {
              id: item.blog.id
            }
          })
        }
      },
      goUser(userId) {
        // console.log('userid', userId)
        this.$router.push({
          path: `/space/${userId}`,
        })
      }
    },
  }
</script>
<style scoped>
  .container {
    width: 625px;
    height: 100%;
    margin-left: 20px;
  }
  .msgItem {
    height: 100%;
    border-radius: 25px;
    border-style: dotted;
    border-color: rgb(96, 193, 245);
    border-width: 2px;
    /* background: #f5f4f4; */
    margin-top: 16px;
    display: flex;
  }
  .left-box {
    width: 60px;
  }
  .avatar {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    float: left;
  }
  .center-box {
    text-align: left;
    margin-left: 10px;
    width: 500px;
  }
  .line1 {
    font-size: 20px;
    text-align: center;
  }
  .msgContent {
    color:rgb(15, 15, 16)
  }
  .msgBlog {
    background: #f5f4f4;
    border-radius: 5px;
    height: 40px;
  }
  .actionBtn {
    margin-left: 10px;
  }
  .actionBtn:hover {
    color: rgb(108, 176, 232);
  }
  .countItem:not(:first-child) {
    margin-left: 12px;
  }
</style>